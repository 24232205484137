{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.ClientConnector;

{$DEFINE NOPP}

interface

uses
  Classes, WEBLib.JSON;

type
  TClientConnectorIdentifier = (ciUnknown, ciAndroid, ciiOS, ciWindows, ciMac);

  TClientConnectorReceivedMessageEvent = procedure(Sender: TObject; AJSON: TJSONObject) of object;

  TClientConnector = class(TComponent)
  private
    FOnReceivedMessage: TClientConnectorReceivedMessageEvent;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Receive(AJSON: string);
    procedure Send(AJSON: TJSONObject);
    procedure SendMessage(AMessage: string);
  published
    property OnReceivedMessage: TClientConnectorReceivedMessageEvent read FOnReceivedMessage write FOnReceivedMessage;
  end;

  TWebClientConnector = class(TClientConnector);

implementation

uses
  Math, JS, WEBLib.Forms, SysUtils, WEBLib.Dialogs;

const
  Prefix: string = 'webclientevent://';

constructor TClientConnector.Create(AOwner: TComponent);
begin
  inherited;
  Application.ClientConnector := Self;
end;

destructor TClientConnector.Destroy;
begin
  Application.ClientConnector := nil;
  inherited;
end;

procedure TClientConnector.Receive(AJSON: string);
var
  js: TJSON;
  o: TJSONObject;
  s: string;
begin
  js := TJSON.Create;
  s := decodeURIComponent(AJSON);
  o := js.parse(s);
  if Assigned(OnReceivedMessage) then
    OnReceivedMessage(Self, o);
  o.Free;
end;

procedure TClientConnector.Send(AJSON: TJSONObject);
var
  s: string;
begin
  s := TJSJSON.stringify(AJSON.JSObject);
  SendMessage(s);
end;

procedure TClientConnector.SendMessage(AMessage: string);
const
  MAXBUFFERCOUNT = 500;
var
  s: string;
  cid: TClientConnectorIdentifier;
  scid: string;
  sSub: string;
  sc: Integer;

  procedure InternalSend(m: string);
  begin
    case cid of
      ciAndroid:
      begin
        asm
          injectedObject.Setjsvalue(m);
          injectedObject.performClick();
        end;
      end;
      ciiOS, ciMac:
      begin
        asm
          window.webkit.messageHandlers.bridge.postMessage(m);
        end;
      end;
      ciWindows:
      begin
        asm
          window.location = m;
        end;
      end;
      ciUnknown: ShowMessage('Please perform handshake from client first.');
    end;
  end;
begin
  s := AMessage;
  asm
    scid = window.TMSWEBCoreClientIdentifier;
  end;

  cid := ciUnknown;
  case LowerCase(scid) of
    'android': cid := ciAndroid;
    'ios': cid := ciiOS;
    'windows': cid := ciWindows;
    'mac', 'macos': cid := ciMac;
  end;


  case LowerCase(scid) of
    'mac', 'macos', 'ios': InternalSend(Prefix + encodeURIcomponent(s));
    'android', 'windows':
    begin
      InternalSend(Prefix + 'BUFFERSTART');
      while Length(s) > 0 do
      begin
        sc := Min(Integer(Length(s)), MAXBUFFERCOUNT);
        sSub := Copy(s, 1, sc);
        Delete(s, 1, sc);
        InternalSend(Prefix + encodeURIcomponent(sSub));
      end;
      InternalSend(Prefix + 'BUFFEREND');
    end;
  end;
end;

end.
