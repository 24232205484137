program Fewo;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  uFewo01 in 'uFewo01.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm2, Form2);
  Application.Run;
end.
