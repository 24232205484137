unit uFewo01;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.WebCtrls,
  WEBLib.ExtCtrls, Vcl.Controls, Vcl.Imaging.jpeg, WEBLib.Slider,
  WEBLib.ComCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCMapsImage,
  WEBLib.Buttons;

type
  TForm2 = class(TForm)
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    paContainerMain01: TPanel;
    WebPanel4: TPanel;
    naName: TLabel;
    WebAccordion1: TAccordion;
    WebPanel5: TPanel;
    WebPanel6: TPanel;
    paContainerBeschreibung01: TPanel;
    WebRichEdit1: TRichEdit;
    imImage01: TTMSFNCMapsImage;
    WebBitBtn2: TBitBtn;
    WebBitBtn1: TBitBtn;
    tiTimer01: TTimer;
    paContainerDetails01: TPanel;
    WebLabel1: TLabel;
    llKontakt01: TLinkLabel;
    llMaps01: TLinkLabel;
    llImpressum01: TLinkLabel;
    paContainerLage01: TPanel;
    reLage01: TRichEdit;
    reDetails01: TRichEdit;
    procedure WebFormResize(Sender: TObject);
    procedure WebAccordion1Expanding(Sender: TObject;
      ASection: TAccordionSection; var Allow: Boolean);
    procedure WebFormCreate(Sender: TObject);
    procedure WebBitBtn2Click(Sender: TObject);
    procedure WebBitBtn1Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure tiTimer01Timer(Sender: TObject);
    procedure WebAccordion1Expanded(Sender: TObject;
      ASection: TAccordionSection);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }

    SL: TStringlist;
  protected procedure LoadDFMValues; override; end;

var
  Form2: TForm2;

implementation

{$R *.dfm}

procedure TForm2.WebAccordion1Expanded(Sender: TObject;
  ASection: TAccordionSection);
begin
 tiTimer01.Enabled := False;
 if WebAccordion1.Sections[0].Expanded then
 begin
  tiTimer01.Enabled := True;
  imImage01.URL := 'https://reusenhaeuser.de/ll/images/slider/'+ SL.Strings[0];
 end;

end;

procedure TForm2.WebAccordion1Expanding(Sender: TObject;
  ASection: TAccordionSection; var Allow: Boolean);
var
 I: Integer;
begin
 for I := 0 to (WebAccordion1.Sections.Count - 1) do
 begin
  WebAccordion1.Sections[I].Expanded := False;
 end;
end;

procedure TForm2.WebBitBtn1Click(Sender: TObject);
begin
 tiTimer01.Enabled := False;
 try
  imImage01.Tag := imImage01.Tag + 1;
  if imImage01.Tag > SL.Count - 1 then
  begin
   imImage01.Tag := 0;
  end;
  imImage01.URL := 'https://reusenhaeuser.de/ll/images/slider/'+ SL.Strings[imImage01.Tag];
 finally
  tiTimer01.Enabled := True;
 end;
end;

procedure TForm2.WebBitBtn2Click(Sender: TObject);
begin
 tiTimer01.Enabled := False;
 try
  imImage01.Tag := imImage01.Tag - 1;
  if imImage01.Tag < 0 then
  begin
   imImage01.Tag := SL.Count - 1;
  end;
  imImage01.URL := 'https://reusenhaeuser.de/ll/images/slider/'+ SL.Strings[imImage01.Tag];
 finally
  tiTimer01.Enabled := True;
 end;
end;

procedure TForm2.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 SL.Free;
end;

procedure TForm2.WebFormCreate(Sender: TObject);
var
 I: Integer;
begin
 SL := TStringList.Create;
  for I := 0 to 14 do
  begin
   SL.Add('sl'+ FormatFloat('00', I) +'.jpg');
  end;
end;

procedure TForm2.WebFormResize(Sender: TObject);
begin
 if (Width > 1024) then
 begin
  WebPanel1.Width := Trunc((Width - 1024) / 2);
  WebPanel2.Width := Trunc((Width - 1024) / 2);
 end
 else
 begin
  WebPanel1.Width := 1;
  WebPanel2.Width := 1;
 end;
 paContainerBeschreibung01.Width := Width - WebPanel1.Width - WebPanel2.Width - 55;
 paContainerDetails01.Width := Width - WebPanel1.Width - WebPanel2.Width - 55;
 paContainerLage01.Width := Width - WebPanel1.Width - WebPanel2.Width - 55;
end;

procedure TForm2.tiTimer01Timer(Sender: TObject);
begin
 WebBitBtn1Click(Sender);
end;

procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebPanel6 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  paContainerMain01 := TPanel.Create(Self);
  WebAccordion1 := TAccordion.Create(Self);
  paContainerBeschreibung01 := TPanel.Create(Self);
  WebRichEdit1 := TRichEdit.Create(Self);
  imImage01 := TTMSFNCMapsImage.Create(Self);
  WebBitBtn2 := TBitBtn.Create(Self);
  WebBitBtn1 := TBitBtn.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  naName := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  llKontakt01 := TLinkLabel.Create(Self);
  llMaps01 := TLinkLabel.Create(Self);
  llImpressum01 := TLinkLabel.Create(Self);
  paContainerDetails01 := TPanel.Create(Self);
  reDetails01 := TRichEdit.Create(Self);
  paContainerLage01 := TPanel.Create(Self);
  reLage01 := TRichEdit.Create(Self);
  tiTimer01 := TTimer.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebPanel6.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  paContainerMain01.BeforeLoadDFMValues;
  WebAccordion1.BeforeLoadDFMValues;
  paContainerBeschreibung01.BeforeLoadDFMValues;
  WebRichEdit1.BeforeLoadDFMValues;
  imImage01.BeforeLoadDFMValues;
  WebBitBtn2.BeforeLoadDFMValues;
  WebBitBtn1.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  naName.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  llKontakt01.BeforeLoadDFMValues;
  llMaps01.BeforeLoadDFMValues;
  llImpressum01.BeforeLoadDFMValues;
  paContainerDetails01.BeforeLoadDFMValues;
  reDetails01.BeforeLoadDFMValues;
  paContainerLage01.BeforeLoadDFMValues;
  reLage01.BeforeLoadDFMValues;
  tiTimer01.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Width := 1028;
    Height := 2000;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 150;
    WebPanel1.Height := 2000;
    WebPanel1.Align := alLeft;
    WebPanel1.BorderColor := clWhite;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.Color := clWhite;
    WebPanel6.SetParentComponent(WebPanel1);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.Left := 0;
    WebPanel6.Top := 0;
    WebPanel6.Width := 150;
    WebPanel6.Height := 61;
    WebPanel6.Align := alTop;
    WebPanel6.BorderColor := 16436871;
    WebPanel6.BorderStyle := bsNone;
    WebPanel6.Color := 16436871;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 878;
    WebPanel2.Top := 0;
    WebPanel2.Width := 150;
    WebPanel2.Height := 2000;
    WebPanel2.Align := alRight;
    WebPanel2.BorderColor := clWhite;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 1;
    WebPanel2.Color := clWhite;
    WebPanel5.SetParentComponent(WebPanel2);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 0;
    WebPanel5.Width := 150;
    WebPanel5.Height := 61;
    WebPanel5.Align := alTop;
    WebPanel5.BorderColor := 16436871;
    WebPanel5.BorderStyle := bsNone;
    WebPanel5.Color := 16436871;
    paContainerMain01.SetParentComponent(Self);
    paContainerMain01.Name := 'paContainerMain01';
    paContainerMain01.Left := 150;
    paContainerMain01.Top := 0;
    paContainerMain01.Width := 728;
    paContainerMain01.Height := 2000;
    paContainerMain01.Align := alClient;
    paContainerMain01.BorderColor := clWhite;
    paContainerMain01.BorderStyle := bsNone;
    paContainerMain01.ChildOrder := 2;
    paContainerMain01.Color := clWhite;
    WebAccordion1.SetParentComponent(paContainerMain01);
    WebAccordion1.Name := 'WebAccordion1';
    WebAccordion1.Left := 0;
    WebAccordion1.Top := 61;
    WebAccordion1.Width := 728;
    WebAccordion1.Height := 1939;
    WebAccordion1.Align := alClient;
    WebAccordion1.ChildOrder := 1;
    WebAccordion1.Sections.Clear;
    with WebAccordion1.Sections.Add do
    begin
      Control := paContainerBeschreibung01;
      Caption := 'Beschreibung';
      Tag := 0;
    end;
    with WebAccordion1.Sections.Add do
    begin
      Control := paContainerDetails01;
      Caption := 'Details ';
      Tag := 0;
    end;
    with WebAccordion1.Sections.Add do
    begin
      Control := paContainerLage01;
      Caption := 'Lage';
      Tag := 0;
    end;
    SetEvent(WebAccordion1, Self, 'OnExpanded', 'WebAccordion1Expanded');
    SetEvent(WebAccordion1, Self, 'OnExpanding', 'WebAccordion1Expanding');
    paContainerBeschreibung01.SetParentComponent(paContainerMain01);
    paContainerBeschreibung01.Name := 'paContainerBeschreibung01';
    paContainerBeschreibung01.Left := 64;
    paContainerBeschreibung01.Top := 149;
    paContainerBeschreibung01.Width := 589;
    paContainerBeschreibung01.Height := 713;
    paContainerBeschreibung01.Anchors := [akLeft,akTop,akRight];
    paContainerBeschreibung01.BorderStyle := bsNone;
    paContainerBeschreibung01.ChildOrder := 2;
    paContainerBeschreibung01.Color := clWhite;
    WebRichEdit1.SetParentComponent(paContainerBeschreibung01);
    WebRichEdit1.Name := 'WebRichEdit1';
    WebRichEdit1.Left := 3;
    WebRichEdit1.Top := 305;
    WebRichEdit1.Width := 577;
    WebRichEdit1.Height := 397;
    WebRichEdit1.Anchors := [akLeft,akTop,akRight];
    WebRichEdit1.BorderStyle := bsNone;
    WebRichEdit1.Color := clWhite;
    WebRichEdit1.Lines.BeginUpdate;
    try
      WebRichEdit1.Lines.Clear;
      WebRichEdit1.Lines.Add('<h3>Das neu erbaute und ab Fr&uuml;hjahr 2019 erstmals zur Vermietung stehende Reusenhaus liegt im alten Fischerdorf Breege-Juliusruh zwischen Bodden und Meer. Die komfortabel ausgestatteten Ferienh&auml;user sind zwei etwa 109 Quadratmeter gro&szlig;e Doppelhaush&auml;lften und erstrecken sich &uuml;ber 2 Etagen. In den insgesamt 3 Schlafzimmern wartet auf Sie ein gro&szlig;es Boxspringbett, ein Doppelbett und 2 Einzelbetten. Die gro&szlig;e K&uuml;che l&auml;dt mit Mikrowelle, Backofen, Kochfeld, Sp&uuml;lmaschine und einer gro&szlig;em K&uuml;chenzeile zum Kochen ein. Es stehen Ihnen 2 B&auml;der und Toiletten zur Verf&uuml;gung, genie&szlig;en sie pure Erholung in der privaten Sauna. Unsere G&auml;ste k&ouml;nnen das W-Lan im gesamten Ferienhaus kostenfrei nutzen.</h3>');
      WebRichEdit1.Lines.Add('<h2><strong>Daten</strong></h2><ul><h3><li><strong>Bis zu 6 Personen</strong></li><li><strong>109qm Wohnfl&auml;che</strong></li><li><strong>3 Schlafzimmer</strong></li><li><strong>2 Badezimmer mit Dusche</strong></li></h3></ul>');
    finally
      WebRichEdit1.Lines.EndUpdate;
    end;
    WebRichEdit1.ReadOnly := True;
    imImage01.SetParentComponent(paContainerBeschreibung01);
    imImage01.Name := 'imImage01';
    imImage01.Left := 50;
    imImage01.Top := 8;
    imImage01.Width := 489;
    imImage01.Height := 291;
    imImage01.ParentDoubleBuffered := False;
    imImage01.Anchors := [akLeft,akTop,akRight];
    imImage01.Color := clBtnFace;
    imImage01.DoubleBuffered := True;
    imImage01.TabOrder := 1;
    imImage01.Bitmap.LoadFromFile('uFewo01.paContainerBeschreibung01.imImage01.Bitmap.jpg');
    imImage01.Fill.Kind := gfkNone;
    imImage01.Stroke.Kind := gskNone;
    imImage01.URL := 'https://reusenhaeuser.de/ll/images/slider/sl02.jpg';
    WebBitBtn2.SetParentComponent(paContainerBeschreibung01);
    WebBitBtn2.Name := 'WebBitBtn2';
    WebBitBtn2.Left := 3;
    WebBitBtn2.Top := 120;
    WebBitBtn2.Width := 41;
    WebBitBtn2.Height := 73;
    WebBitBtn2.Color := clNone;
    WebBitBtn2.Flat := True;
    WebBitBtn2.Glyph.LoadFromFile('uFewo01.paContainerBeschreibung01.WebBitBtn2.Glyph.jpg');
    WebBitBtn2.HeightPercent := 100.000000000000000000;
    WebBitBtn2.WidthPercent := 100.000000000000000000;
    SetEvent(WebBitBtn2, Self, 'OnClick', 'WebBitBtn2Click');
    WebBitBtn1.SetParentComponent(paContainerBeschreibung01);
    WebBitBtn1.Name := 'WebBitBtn1';
    WebBitBtn1.Left := 545;
    WebBitBtn1.Top := 120;
    WebBitBtn1.Width := 41;
    WebBitBtn1.Height := 73;
    WebBitBtn1.Anchors := [akTop,akRight];
    WebBitBtn1.Color := clNone;
    WebBitBtn1.Flat := True;
    WebBitBtn1.Glyph.LoadFromFile('uFewo01.paContainerBeschreibung01.WebBitBtn1.Glyph.jpg');
    WebBitBtn1.HeightPercent := 100.000000000000000000;
    WebBitBtn1.WidthPercent := 100.000000000000000000;
    SetEvent(WebBitBtn1, Self, 'OnClick', 'WebBitBtn1Click');
    WebPanel4.SetParentComponent(paContainerMain01);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 0;
    WebPanel4.Width := 728;
    WebPanel4.Height := 61;
    WebPanel4.Align := alTop;
    WebPanel4.BorderColor := 16436871;
    WebPanel4.BorderStyle := bsNone;
    WebPanel4.Color := 16436871;
    naName.SetParentComponent(WebPanel4);
    naName.Name := 'naName';
    naName.Left := 6;
    naName.Top := 3;
    naName.Width := 150;
    naName.Height := 29;
    naName.Caption := 'Reusenh'#228'user';
    naName.Font.Charset := DEFAULT_CHARSET;
    naName.Font.Color := clWindowText;
    naName.Font.Height := -24;
    naName.Font.Name := 'Tahoma';
    naName.Font.Style := [];
    naName.HeightPercent := 100.000000000000000000;
    naName.ParentFont := False;
    naName.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(WebPanel4);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 6;
    WebLabel1.Top := 30;
    WebLabel1.Width := 59;
    WebLabel1.Height := 23;
    WebLabel1.Caption := 'Breege';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    llKontakt01.SetParentComponent(WebPanel4);
    llKontakt01.Name := 'llKontakt01';
    llKontakt01.Left := 685;
    llKontakt01.Top := 23;
    llKontakt01.Width := 37;
    llKontakt01.Height := 13;
    llKontakt01.Anchors := [akTop,akRight];
    llKontakt01.Color := 16436871;
    llKontakt01.HeightPercent := 100.000000000000000000;
    llKontakt01.WidthPercent := 100.000000000000000000;
    llKontakt01.Caption := '<a href="mailto:Kontakt@reusenhaeuser.de ?subject=Anfrage Reusenhaus">Kontakt</a>';
    llMaps01.SetParentComponent(WebPanel4);
    llMaps01.Name := 'llMaps01';
    llMaps01.Left := 696;
    llMaps01.Top := 4;
    llMaps01.Width := 26;
    llMaps01.Height := 13;
    llMaps01.Anchors := [akTop,akRight];
    llMaps01.Color := 16436871;
    llMaps01.HeightPercent := 100.000000000000000000;
    llMaps01.WidthPercent := 100.000000000000000000;
    llMaps01.Caption := '<a href="https://www.google.de/maps/place/Reusenh%C3%A4user+Breege/@54.614579,13.3584865,17z/data=!3m1!4b1!4m5!3m4!1s0x47ab519f5f420cf3:0xfaf43035623e0444!8m2!3d54.6145418!4d13.3584824">Karte</a>';
    llImpressum01.SetParentComponent(WebPanel4);
    llImpressum01.Name := 'llImpressum01';
    llImpressum01.Left := 606;
    llImpressum01.Top := 42;
    llImpressum01.Width := 116;
    llImpressum01.Height := 13;
    llImpressum01.Anchors := [akTop,akRight];
    llImpressum01.Color := 16436871;
    llImpressum01.HeightPercent := 100.000000000000000000;
    llImpressum01.WidthPercent := 100.000000000000000000;
    llImpressum01.Caption := '<a href="https://reusenhaeuser.de/ll/impressumdatenschutz.html">Impressum/Datenschutz</a>';
    paContainerDetails01.SetParentComponent(paContainerMain01);
    paContainerDetails01.Name := 'paContainerDetails01';
    paContainerDetails01.Left := 9;
    paContainerDetails01.Top := 868;
    paContainerDetails01.Width := 713;
    paContainerDetails01.Height := 718;
    paContainerDetails01.Anchors := [akLeft,akTop,akRight];
    paContainerDetails01.BorderStyle := bsNone;
    paContainerDetails01.ChildOrder := 2;
    paContainerDetails01.Color := clWhite;
    reDetails01.SetParentComponent(paContainerDetails01);
    reDetails01.Name := 'reDetails01';
    reDetails01.Left := 9;
    reDetails01.Top := 19;
    reDetails01.Width := 701;
    reDetails01.Height := 686;
    reDetails01.Anchors := [akLeft,akTop,akRight];
    reDetails01.BorderStyle := bsNone;
    reDetails01.Color := clWhite;
    reDetails01.Lines.BeginUpdate;
    try
      reDetails01.Lines.Clear;
      reDetails01.Lines.Add('<h2><strong>Ausstattung</strong></h2><ul><h3><li><strong>Haustiere nicht erlaubt</strong></li><li><strong>WLAN</strong></li><li><strong>Garten</strong></li><li><strong>Fernseher</strong></li><li><strong>Terrasse</strong></li><li><strong>Sp&uuml;hlmaschine</strong></li><li><strong>Waschmaschine</strong></li><li><strong>Sauna</strong></li><li><strong>Parkplatz</strong></li></h3></ul>');
      reDetails01.Lines.Add('');
      reDetails01.Lines.Add('<h2><strong>Au&szlig;enbereich</strong></h2><ul><h3><li><strong>Garten</strong></li><li><strong>Grill</strong></li><li><strong>Parkplatz</strong></li><li><strong>Terrasse</strong></li></h3></ul>');
      reDetails01.Lines.Add('');
      reDetails01.Lines.Add('<h2><strong>Innenbereich</strong></h2><ul><h3><li><strong>Sauna</strong></li><li><strong>Zentralheizung</strong></li><li><strong>K&uuml;chenzeile</strong></li><li><strong>sep. Eingang</strong></li><li><strong>Heizung</strong></li></h3></ul>');
      reDetails01.Lines.Add('');
      reDetails01.Lines.Add('<h2><strong>Schlafzimmer</strong></h2><ul><h3><li><strong>1 Zimmer mit 2&nbsp; Einzelbetten</strong></li><li><strong>2 Zimmer mit Doppelbett</strong></li></h3></ul>');
      reDetails01.Lines.Add('');
      reDetails01.Lines.Add('<h2><strong>Badezimmer</strong></h2><ul><h3><li><strong>2 Badezimmer mit Dusche</strong></li></h3></ul>');
      reDetails01.Lines.Add('');
      reDetails01.Lines.Add('<h2><strong>K&uuml;che</strong></h2><ul><h3><li><strong>4-Plattenherd</strong></li>'#9'<li><strong>Gefrierfach</strong></li>'#9'<li><strong>Sp&uuml;hlmaschine</strong></li>'#9'<li><strong>K&uuml;hlschrank</strong></li>'#9'<li><strong>Mikrowelle</strong></li>'#9'<li><strong>Backofen</strong></li><li><strong>Wasserkocher</strong></li><li><strong>Kaffeemaschine</strong></li><li><strong>Toaster</strong></li><li><strong>Besteck</strong></li><li><strong>Gl&auml;ser</strong></li><li><strong>Geschirr</strong></li><li><strong>Induktionsfeld</strong></li><li><strong>Dunstabzug</strong></li></h3></ul>');
      reDetails01.Lines.Add('');
      reDetails01.Lines.Add('<h2><strong>Ger&auml;te und Zubeh&ouml;r</strong></h2><ul><h3><li><strong>Bettw&auml;sche</strong></li><li><strong>Fernseher</strong></li><li><strong>Handt&uuml;cher</strong></li><li><strong>Satelliten-/Kabel-TV</strong></li><li><strong>Waschmaschine</strong></li><li><strong>Kinderhochstuhl</strong></li><li><strong>WLAN</strong></li><li><strong>B&uuml;geleisen</strong></li><li><strong>B&uuml;gelbrett</strong></li><li><strong>Staubsauger</strong></li><li><strong>Racuhmelder</strong></li><li><strong>Sonnenschrim</strong></li><li><strong>Terrassenm&ouml;bel</strong></li></h3></ul>');
    finally
      reDetails01.Lines.EndUpdate;
    end;
    reDetails01.ReadOnly := True;
    paContainerLage01.SetParentComponent(paContainerMain01);
    paContainerLage01.Name := 'paContainerLage01';
    paContainerLage01.Left := 9;
    paContainerLage01.Top := 1592;
    paContainerLage01.Width := 713;
    paContainerLage01.Height := 493;
    paContainerLage01.Anchors := [akLeft,akTop,akRight];
    paContainerLage01.BorderStyle := bsNone;
    paContainerLage01.ChildOrder := 2;
    paContainerLage01.Color := clWhite;
    reLage01.SetParentComponent(paContainerLage01);
    reLage01.Name := 'reLage01';
    reLage01.Left := 6;
    reLage01.Top := 25;
    reLage01.Width := 701;
    reLage01.Height := 465;
    reLage01.Anchors := [akLeft,akTop,akRight];
    reLage01.BorderStyle := bsNone;
    reLage01.Color := clWhite;
    reLage01.Lines.BeginUpdate;
    try
      reLage01.Lines.Clear;
      reLage01.Lines.Add('<h3><p>Die &quot;<a href="https://www.google.de/maps/place/Reusenh%C3%A4user+Breege/@54.614579,13.3584865,17z/data=!3m1!4b1!4m5!3m4!1s0x47ab519f5f420cf3:0xfaf43035623e0444!8m2!3d54.6145418!4d13.3584824">Reusenh'#228'user</a>&quot; bestehen aus 2 Doppelhaush&auml;lften und wurden auf einem ca. 600 m&sup2; gro&szlig;en Teil des ehemaligen Reusenplatzes im Jahr 2018 erbaut - ein paar hundert Meter weit entfernt befindet sich der Breeger Hafen - hier ist auch der Ausgangspunkt f&uuml;r Schiffsfahrten nach&nbsp;Hiddensee&nbsp;und f&uuml;r Boddenrundfahrten - das Reusenhaus S&uuml;d hat vom Obergeschoss teilweise einen Blick auf den Breeger Bodden und befindet sich ca. 1500 m vom Ostseebad Breege-Juliusruh&nbsp;mit seinem weitl&auml;ufigen Sandstrand entfernt - das Reusenhaus S&uuml;d bietet eine gehobene, moderne Ausstattung f&uuml;r bis zu 6 Personen - kostenlose Sauna, kostenloses W-LAN, eine &uuml;berdachte All-Wetter-Terrasse mit Bestuhlung und Grill, Fu&szlig;bodenheizung im gesamten Haus garantieren einen angenehmen Aufenthalt - die Kurabgabe (je Nacht / je Person) wird noch separat berechnet - ein W&auml;schepaket (Bettw&auml;sche / gro&szlig;es und kleines Handtuch) kann pro Person vor Ort f&uuml;r 17,50 EUR erworben werden (dies sollte vorab angemeldet werden) - die Preise verstehen sich bis zu maximal 4 Personen - jede weitere Person &auml;lter als 5 Jahre kostet 10 EUR separat. &Uuml;ber einen Cashback &quot;Sauberkeit&amp;Umgang&quot; haben Sie als Gast die M&ouml;glichkeit, bei Erf&uuml;llung verschiedener Kriterien bez&uuml;glich Sauberkeit und Umgang mit der Unterkunft pro Nacht max. 10 EUR / max. aber 70 EUR je Aufenthalt nach Abreise zur&uuml;ck erstattet zu bekommen.</p><p>Freizeitm&ouml;glichkeiten:</p><ul>');
      reLage01.Lines.Add(#9'<li>Angeln</li>');
      reLage01.Lines.Add(#9'<li>Fahrradfahren</li>');
      reLage01.Lines.Add(#9'<li>Reiten</li>');
      reLage01.Lines.Add(#9'<li>Schwimmen</li>');
      reLage01.Lines.Add(#9'<li>Segeln</li>');
      reLage01.Lines.Add(#9'<li>Surfen</li>');
      reLage01.Lines.Add('                <li>Wandern</li></h3>  ');
      reLage01.Lines.Add('</ul>');
    finally
      reLage01.Lines.EndUpdate;
    end;
    reLage01.ReadOnly := True;
    tiTimer01.SetParentComponent(Self);
    tiTimer01.Name := 'tiTimer01';
    tiTimer01.Enabled := False;
    tiTimer01.Interval := 10000;
    SetEvent(tiTimer01, Self, 'OnTimer', 'tiTimer01Timer');
    tiTimer01.Left := 96;
    tiTimer01.Top := 72;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebPanel6.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    paContainerMain01.AfterLoadDFMValues;
    WebAccordion1.AfterLoadDFMValues;
    paContainerBeschreibung01.AfterLoadDFMValues;
    WebRichEdit1.AfterLoadDFMValues;
    imImage01.AfterLoadDFMValues;
    WebBitBtn2.AfterLoadDFMValues;
    WebBitBtn1.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    naName.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    llKontakt01.AfterLoadDFMValues;
    llMaps01.AfterLoadDFMValues;
    llImpressum01.AfterLoadDFMValues;
    paContainerDetails01.AfterLoadDFMValues;
    reDetails01.AfterLoadDFMValues;
    paContainerLage01.AfterLoadDFMValues;
    reLage01.AfterLoadDFMValues;
    tiTimer01.AfterLoadDFMValues;
  end;
end;

end.
